import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import nookies from 'nookies';

// adapters
import { logger } from 'adapters/Logger';

// constants
import {
  DATA_FORM,
  DATA_LEAD,
  LS_ENCODED_DATA_FORM,
  LS_ENCODED_PAYMENT_PROFILE_ID,
  PURCHASED_PROFILES_AMOUNT,
} from '../constants/';

// utils and hooks
import { useCart } from 'hooks/useCart';
import { useProductFlow } from '../hooks/useProductFlow';
import useSessionStorage from 'hooks/useSessionStorage';
import { useLocalStorage } from 'hooks/useLocalStorage';
import scrollTo from 'utils/scrollTo';
import { sendDataToAnalytics } from 'utils/sendDataToAnalytics';
import { getProductOnServerSide, normalizePhone } from 'utils/globalFunctions';
import setContextDataLead from '../utils/setContextDataLead';
import { validateTrialFluxByLeadId } from 'services/repositories/leads/validateTrialFluxByLeadId';

// screens
import DsLeadScreen from 'screens/DS/LeadScreen';

// components
import LeadForm from '../components/LeadForm';
import { getSellerData } from 'services/repositories';
import { useRouterQuery } from 'hooks/useRouterQuery';

export default function Lead({ query, agentLead }) {
  const [, setDataForm] = useSessionStorage(DATA_FORM);
  const [, setDataLead] = useSessionStorage(DATA_LEAD);
  const [, setPurchasedProfilesAmount, removePurchasedProfilesAmount] =
    useSessionStorage(PURCHASED_PROFILES_AMOUNT, null);

  const removeEncodedDataFormFromLocalStorage = useLocalStorage(
    LS_ENCODED_DATA_FORM,
    null
  )[2];
  const removeEncodedPaymentProfileIdFromLocalStorage = useLocalStorage(
    LS_ENCODED_PAYMENT_PROFILE_ID,
    null
  )[2];

  const router = useRouter();
  const routerQuery = useRouterQuery();
  const context = useCart();

  const { nextStep, productFlow } = useProductFlow();

  const isProductFlowsAvailable =
    context.productTheme.data?.isProductFlowsAvailable;

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    if (typeof window !== 'undefined') {
      scrollTo(0);
    }

    removeEncodedDataFormFromLocalStorage();
    removeEncodedPaymentProfileIdFromLocalStorage();

    sessionStorage?.removeItem('shouldUseUpsellTheme');
    sessionStorage?.removeItem('bankslipUrl');
    sessionStorage?.removeItem('isBankslip');
    sessionStorage?.removeItem('liveloProductDetails');
    sessionStorage?.removeItem('liveloDevice');
    sessionStorage?.removeItem('liveloPaymentMethod');
    sessionStorage?.removeItem('paymentProfileId');
    sessionStorage?.removeItem('product');
    sessionStorage?.removeItem('sfSubscriptionId');
    sessionStorage?.removeItem('invoiceProduct');
    sessionStorage?.removeItem('redirect-success');
    removePurchasedProfilesAmount();

    sendDataToAnalytics(
      {
        userEmail: context?.userEmail || '',
        source: routerQuery.source || '',
        seller: routerQuery.seller || '',
        system: context?.productBusiness || '',
        _ga: context?._ga || '',
      },
      'lead',
      'load'
    );
    router.replace(`/lead${context.rawQuery}`);
  }, []);

  const validateTrialFluxPresentationByLeadId = async (leadId) => {
    try {
      const response = await validateTrialFluxByLeadId(leadId);
      /**
       * Caso o aluno já tenha utilizado o trial, devemos redirecioná-lo para o fluxo de  trial-already-used.
       */
      if (!response.canPurchaseTrial) {
        const flows = context.allCheckoutFlowsByProductCode;
        const newProductFlow = flows
          ? flows.find((flow) => flow.id === 'trial-already-used')
          : null;

        if (newProductFlow) {
          context.setProductFlow(newProductFlow);

          router.push({
            pathname: nextStep?.route,
            query: {
              ...routerQuery,
              flowId: 'trial-already-used',
            },
          });
        }
      }
    } catch (error) {
      console.error('Error validating trial flux:', error);
    }
  };

  const onSubmitSuccess = async (routeResponse, providedInformation) => {
    delete providedInformation.captchaToken;

    if (routeResponse) {
      setContextDataLead(routeResponse, providedInformation, context);
      Cookies.set('email', providedInformation?.email);
      setDataForm(providedInformation);
      setDataLead({
        ...routeResponse,
        phone: providedInformation.phone,
      });
      const redirectRoute = routeResponse?.route;

      if (redirectRoute.includes('renew')) {
        router.push({
          pathname: `/${redirectRoute}`,
          query: routerQuery,
        });

        context.setLoading(false);
        return;
      }

      let redirect = redirectRoute?.toLowerCase();

      if (redirect === 'multiprofile-upsell-presentation') {
        /** significa que o usuário tem um plano base multiprofile */
        setPurchasedProfilesAmount(2);

        router.push({
          pathname: `/${redirect}`,
          query: routerQuery,
        });

        context?.setLoading(false);
        return;
      }

      if (redirect === 'payment/upsell') {
        router.push({
          pathname: `/${redirect}`,
          query: routerQuery,
        });

        context?.setLoading(false);
        return;
      }

      if (redirect?.toUpperCase() === 'TRIAL') {
        redirect = 'video';
      }

      if (context.rawQuery) {
        if (query?.route === 'direct-payment') {
          redirect = `payment${context.rawQuery}`;
        } else if (['livelo', 'livelo-accrual'].includes(query?.route)) {
          redirect = `livelo/cpf${context.rawQuery}`;
        } else if (['vip', 'vip-special'].includes(query?.route)) {
          redirect = `payment${context.rawQuery}`;
        } else redirect = `${redirect}${context.rawQuery}`;
      }

      const isTrialDefaultFlow = productFlow?.id == 'trial';
      if (isTrialDefaultFlow) {
        await validateTrialFluxPresentationByLeadId(routeResponse?.leadId);
      }

      if (isProductFlowsAvailable) {
        router.push({
          pathname: nextStep?.route,
          query: routerQuery,
        });
      } else {
        router.push(`/${redirect}`);
      }

      context?.setLoading(false);
    }
  };

  const renderForm = (hideForm = false) => {
    const formatedValues =
      context?.product === 'POWERHOUSE'
        ? { name: '', email: '', phone: '' }
        : {
            ...agentLead,
            phone: agentLead?.phone
              ? normalizePhone(agentLead?.phone || '')?.value
              : '',
          };

    if (
      (context?.productTheme?.data?.isThemeUsingAllDsTokens ||
        ['WUP', 'MS'].includes(context?.product?.toUpperCase())) &&
      context?.area?.toUpperCase() === 'BR'
    ) {
      return (
        <DsLeadScreen
          contactValues={formatedValues}
          onSubmitSuccess={(routeResponse, provided) =>
            onSubmitSuccess(routeResponse, provided)
          }
        />
      );
    }

    return (
      <LeadForm
        query={query}
        hideForm={hideForm}
        values={formatedValues}
        product={context.product}
        onSubmitSuccess={(routeResponse, providedInformation) =>
          onSubmitSuccess(routeResponse, providedInformation)
        }
      />
    );
  };

  return (
    <div className="leadWrapper">
      <div
        style={{
          marginTop: 0,
          padding: 20,
        }}
      >
        {renderForm()}
      </div>
    </div>
  );
}

export const getServerSideProps = async (ctx) => {
  const cookies = nookies.get(ctx);
  const { query, req } = ctx;
  const url = ctx?.req?.originalUrl || ctx?.req?._parsedUrl?.path;
  const isServer = !!req;
  let agentLead = null;
  const operationByLang = {
    'pt-mz': 'MZ',
    es: 'ES',
    DEFAULT: 'BR',
  };
  const operation = operationByLang[query?.lang?.toLowerCase() || 'DEFAULT'];

  const productId = getProductOnServerSide({ ...ctx });

  try {
    if (query?.contactId) {
      const { data } = await getSellerData(
        {
          operation,
          product: productId,
          seller: query.seller,
          source: query.contactId,
        },
        isServer
      );
      agentLead = {
        name: data?.fullName || '',
        email: data?.email || '',
        phone: data?.phone || '',
      };
    }

    return {
      props: {
        query,
        agentLead,
      },
    };
  } catch (error) {
    console.error('Lead.getServerSideProps error: ', JSON.stringify(error));

    if (!error?.code) {
      logger.error(
        error,
        {
          url,
          query: ctx?.query,
        },
        {
          originalException: error,
          isServer,
          url,
          title: `Lead.getServerSideProps error: ${error?.message}`,
          query: ctx?.query,
          type: error?.name,
          userEmail: cookies?.email,
          userId: cookies?._ga,
        }
      );
    }

    return {
      redirect: {
        destination: 'https://wiseup.com/online/error/',
        permanent: false,
      },
    };
  }
};
